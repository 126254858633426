<template>
  <div>
    <b-row v-if="portfolios.length > 0">
      <b-col>
        <b-card title="Portföyüm">
          <b-table
            :fields="fields"
            :items="portfolios"
          >
            <template #cell(startup)="data">
              <b-row>
                <b-col>
                  <div>
                    <div class="font-weight-bold text-primary font-medium-1">
                      {{ data.item.startup }}
                    </div>
                    <small>{{ data.item.company }}</small>
                  </div>
                </b-col>
              </b-row>
            </template>
            <template #cell(edate)="data">
              <b-row>
                <b-col>
                  <div>
                    <div class="font-weight-bold font-medium-1">
                      {{ moment(data.item.edate).format('LL') }}
                    </div>
                  </div>
                </b-col>
              </b-row>
            </template>
            <template #cell(control)="data">
              <b-dropdown
                text="Raporlar"
                variant="primary"
                size="sm"
              >
                <b-dropdown-item :to="'raporlar/girisim-raporu/' + data.item.id">
                  Girişim Raporu
                </b-dropdown-item>
                <b-dropdown-item :to="'raporlar/pazar-analizi-raporu/' + data.item.id">
                  Pazar Analizi Raporu
                </b-dropdown-item>
                <b-dropdown-item :to="'raporlar/is-modeli-performansi/' + data.item.id">
                  İş Modeli Performansı
                </b-dropdown-item>
                <b-dropdown-item :to="'raporlar/is-modeli-kanvasi/' + data.item.id">
                  İş Modeli Kanvası
                </b-dropdown-item>
                <b-dropdown-item :to="'raporlar/teknoloji-hazirlik-seviyesi/' + data.item.id">
                  Teknoloji Hazırlık Seviyesi
                </b-dropdown-item>
                <b-dropdown-item :to="'raporlar/yatirimci-ozet-sayfasi/' + data.item.id">
                  Yatırımcı Özet Sayfası
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <div v-else>
      <b-alert
        variant="warning"
        show
        class="p-1"
      >
        <div class="alert-body text-center">
          <span class="d-block mb-2">{{ $t('Henüz portföyünüzde girişim yok.') }}</span>
          <b-button
            variant="primary"
            :to="'/girisimler'"
          >
            {{ $t('Girişimleri Keşfet') }}
          </b-button>
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BDropdown, BDropdownItem, BAlert, BButton,
} from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BCard, BRow, BCol, BTable, BDropdown, BDropdownItem, BAlert, BButton,
  },
  data() {
    return {
      fields: [
        {
          key: 'startup',
          label: 'Girişim',
        },
        {
          key: 'edate',
          label: 'Bitiş Tarihi',
          thStyle: { width: '200px' },
        },
        {
          key: 'control',
          label: 'Kontrol',
          thStyle: { width: '140px' },
        },
      ],
    }
  },
  computed: {
    portfolios() {
      return this.$store.getters['portfolio/getPortfolios']
    },
  },
  created() {
    this.getPortfolios()
  },
  methods: {
    getPortfolios() {
      this.$store.dispatch('portfolio/portfoliosList')
    },
  },
}
</script>
<style scoped></style>
